<template>
  <div class="upload-box">
    <div class="avatar-uploader-box">

      <!-- 上传的图片 -->
      <div
        v-for="(item, i) in imageUrlList"
        :key="i"
        class="avatar-box"
      >
        <el-image
          style="width: 100px;"
          :src="item"
          :preview-src-list="imageUrlList"
        />
        <div
          v-if="!disabled"
          class="delete-icon"
          @click="delImageUrl(i)"
        >
          <i class="el-icon-circle-close" />
        </div>
      </div>

      <!-- 方框样式 -->
      <el-upload
        v-if="type === 'avatar' && imageUrlList.length < maxNum && !disabled"
        ref="avatarUploader"
        :action="$api.nb_common.newUpload"
        class="avatar-uploader"
        :show-file-list="false"
        :on-change="handleAvatarChange"
        :auto-upload="false"
        accept="image/*"
      >
        <div
          v-if="imageUrlList.length < maxNum"
          v-loading="loading"
          element-loading-text="上传中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="line-height: 30px"
        >
          <i class="el-icon-plus avatar-uploader-icon" />
        </div>
      </el-upload>
      <!-- <draggable
        v-model="imageUrlList"
        class="drag-box"
        element="div"
        v-bind="dragOptions"
        :move="onMove"
        @start="isDragging=true"
        @end="isDragging=false"
        @change="changImgList"
      >

      </draggable> -->
    </div>

    <!-- 裁剪图片 start-->
    <vue-cropper-item
      ref="VueCropperItem"
      @confirm="confirmCropper"
    />
    <!-- 裁剪图片 end-->
  </div>
</template>

<script>
import vueCropperItem from './vueCropperItem'
import draggable from 'vuedraggable'
import { upload } from '@/utils/oss'

export default {
  name: 'CutImg',
  components: {
    vueCropperItem,
    draggable
  },
  props: {
    type: {
      type: String,
      default: 'avatar'
    },
    url: {
      type: Array,
      default: () => []
    },
    autoCropWidth: {
      type: Number,
      default: 275
    },
    autoCropHeight: {
      type: Number,
      default: 206
    },
    fixedBox: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    isCompress: {
      // 是否压缩
      type: Boolean,
      default: true
    },
    compress: {
      // 压缩率
      type: String,
      default: '0.8'
    },
    maxNum: {
      type: Number,
      default: 6
    },
    maxSize: {
      type: Number,
      default: 2 * 1024
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // dragOptions: {
      //   group: 'name'
      // },
      loading: false,
      isProgress: false,
      percentage: 0,
      imageUrlList: [],
      showBigImgIndex: 0
    }
  },
  watch: {
    url() {
      this.imageUrlList = this.url
    }
  },
  created() {
    this.imageUrlList = this.url
  },
  methods: {
    // onMove({ relatedContext, draggedContext }) {
    //   const relatedElement = relatedContext.element
    //   const draggedElement = draggedContext.element
    //   return (
    //     (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
    //   )
    // },
    // changImgList() {
    //   console.log(this.imageUrlList,9999)
    // },
    getImgList() {
      return this.imageUrlList
    },
    // 上传之前
    beforeAvatarUpload(file) {
      return new Promise((resolve) => {
        if (file.size > this.maxSize * 1024) {
          this.$message.error(`图片体积过大`)
        } else {
          resolve(true)
        }
      })
    },

    // 上传改变
    async handleAvatarChange(file, fileList) {
      this.$refs.VueCropperItem.imgType = file.raw.type
      await this.beforeAvatarUpload(file)
      console.log('判断文件类型：',file)
      if(file.raw.type == 'image/gif'){
        this.confirmCropper(file.raw)
      }else{
        this.showVueCropperItem(file)
      }
    },


    // 删除图片
    delImageUrl(i) {
      this.$confirm('是否确认移除图片?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.imageUrlList.splice(i, 1)
          this.$emit('change', this.imageUrlList)
        })
        .catch(() => {})
    },

    // 显示裁剪框
    showVueCropperItem(file) {
      this.$refs.VueCropperItem.init()
      this.$refs.VueCropperItem.fileName = file.name

      this.$refs.VueCropperItem.options.img = URL.createObjectURL(file.raw)
      this.setVueCropperOptions()
    },

    // 确认裁剪后上传
    async confirmCropper(file) {
      this.loading = true
      await upload(file).then((res)=>{
        const url = res.url.split('?')[0];
        this.imageUrlList.push(url)
        this.isProgress = false
        this.$emit('change', this.imageUrlList)
      }).finally(()=>{
        this.loading = false;
      })
    },

    // 设置裁剪的配置
    setVueCropperOptions() {
      this.$refs.VueCropperItem.options.autoCropWidth = this.autoCropWidth
      this.$refs.VueCropperItem.options.autoCropHeight = this.autoCropHeight
      this.$refs.VueCropperItem.options.fixedBox = this.fixedBox
      this.$refs.VueCropperItem.options.fixed = this.fixed

      this.$refs.VueCropperItem.isCompress = this.isCompress // 是否压缩图片
      this.$refs.VueCropperItem.compress = this.compress // 压缩率
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-box {
  .drag-box {
    display: flex;
    flex-wrap: wrap;
  }
  .avatar-uploader-box {
    position: relative;
    line-height: 0;
    width: fit-content; //  收缩与包裹,收缩到合适
    display: flex;
    flex-wrap: wrap;
    .avatar-box {
      position: relative;
      margin-right: 10px;
      border-radius: 3px;
      overflow: hidden;
      // border: 1px solid #dcdfe6;
      margin-bottom: 10px;
      .el-image {
        border: 1px solid #dcdfe6;
      }
      .delete-icon {
        position: absolute;
        cursor: pointer;
        top: 0px;
        right: 0px;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 0 0 0 10px;
        background: rgba(0, 0, 0, 0.3);
        line-height: 20px;
        text-align: center;
      }
    }
    .avatar {
      width: 100px;
      height: auto;
    }
    .avatar-uploader {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      margin-bottom: 10px;
      overflow: hidden;
      margin-right: 10px;
      height: 100px;
      &:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        min-width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
      }
      .avatar {
        min-width: 100px;
        display: block;
      }
    }
    .progress-box {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 99%;
      height: 99%;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar-uploader-popup {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 20px;
      border-radius: 6px;
      i {
        width: 30%;
        text-align: center;
        padding: 0 5%;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
}
</style>
