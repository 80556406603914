import Layout from '@/layout/index.vue';

const goodsRouter = {
  path: '',
  component: Layout,
  meta: { title: '商品' },
  children: [
    {
      path: '/nb/goods/attr',
      component: () => import('@/views/nb/goods/attr/list.vue'),
      meta: {
        title: '属性列表',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/attr/detail',
      component: () => import('@/views/nb/goods/attr/detail.vue'),
      meta: {
        title: '属性详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/attr/group',
      component: () => import('@/views/nb/goods/attr/groupList.vue'),
      meta: {
        title: '属性组列表',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/attr/group/detail',
      component: () => import('@/views/nb/goods/attr/groupDetail.vue'),
      meta: {
        title: '属性组详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/sku',
      component: () => import('@/views/nb/goods/sku/index.vue'),
      meta: {
        title: '规格管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/sku/detail',
      component: () => import('@/views/nb/goods/sku/detail.vue'),
      meta: {
        title: '规格详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/skugroup',
      component: () => import('@/views/nb/goods/skugroup/index.vue'),
      meta: {
        title: '规格组管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/skugroup/detail',
      component: () => import('@/views/nb/goods/skugroup/detail.vue'),
      meta: {
        title: '规格组详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/taggoods',
      component: () => import('@/views/nb/goods/taggoods/index.vue'),
      meta: {
        title: '商品标签管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/taggoods/detail',
      component: () => import('@/views/nb/goods/taggoods/detail.vue'),
      meta: {
        title: '商品标签详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/taggoodsgroup',
      component: () => import('@/views/nb/goods/taggoodsgroup/index.vue'),
      meta: {
        title: '商品标签组管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/taggoodsgroup/detail',
      component: () => import('@/views/nb/goods/taggoodsgroup/detail.vue'),
      meta: {
        title: '商品标签组详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/unit',
      component: () => import('@/views/nb/goods/unit/index.vue'),
      meta: {
        title: '单位管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/unit/detail',
      component: () => import('@/views/nb/goods/unit/detail.vue'),
      meta: {
        title: '单位详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/cat/late',
      component: () => import('@/views/nb/goods/cat/late.vue'),
      meta: {
        title: '后台类目列表',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/cat/late/detail',
      component: () => import('@/views/nb/goods/cat/lateDetail.vue'),
      meta: {
        title: '后台类目详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/cat/late/setAttr',
      component: () => import('@/views/nb/goods/cat/late/setAttr.vue'),
      meta: {
        title: '后台类目关联设置',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/cat/front',
      component: () => import('@/views/nb/goods/cat/front.vue'),
      meta: {
        title: '前台类目列表',
        icon: 'iconcaiwurenminbizhifux',
        keepAlive: true,
      },
    },
    {
      path: '/nb/goods/cat/front/detail',
      component: () => import('@/views/nb/goods/cat/frontDetail.vue'),
      meta: {
        title: '前台类目详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    // 商品管理
    {
      path: '/nb/goods/index',
      component: () => import('@/views/nb/goods/goods/index.vue'),
      meta: {
        title: '商品管理',
        icon: 'iconcaiwurenminbizhifux',
        keepAlive: true,
      },
    },
    {
      path: '/nb/goods/detail',
      component: () => import('@/views/nb/goods/goods/detail.vue'),
      meta: {
        title: '商品详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/add',
      component: () => import('@/views/nb/goods/goods/add.vue'),
      meta: {
        title: '新增商品',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/equityCardSet',
      component: () => import('@/views/nb/goods/goods/equityCardSet/index.vue'),
      meta: {
        title: '权益卡设置',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    // 快递列表
    {
      path: '/nb/goods/freight',
      component: () => import('@/views/nb/freight/index.vue'),
      meta: {
        title: '模板列表',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    // 快递详情-普通
    {
      path: '/nb/goods/freight/common',
      component: () => import('@/views/nb/freight/common.vue'),
      meta: {
        title: '模板详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    // 快递详情-同城
    {
      path: '/nb/goods/freight/city',
      component: () => import('@/views/nb/freight/city.vue'),
      meta: {
        title: '模板详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    // 快递详情-自提
    {
      path: '/nb/goods/freight/self',
      component: () => import('@/views/nb/freight/self.vue'),
      meta: {
        title: '模板详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    // 标签管理
    {
      path: '/nb/goods/tag',
      component: () => import('@/views/nb/goods/tag/index.vue'),
      meta: {
        title: '标签管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    // 品牌管理
    {
      path: '/nb/goods/brand',
      component: () => import('@/views/nb/goods/brand/index.vue'),
      meta: {
        title: '品牌管理',
        keepAlive: true,
      },
    },
    // 品牌详情
    {
      path: '/nb/goods/brand/detail',
      component: () => import('@/views/nb/goods/brand/detail.vue'),
      meta: {
        title: '品牌详情',
      },
    },
    // 评论管理
    {
      path: '/nb/goods/comment',
      component: () => import('@/views/nb/goods/comment/index.vue'),
      meta: {
        title: '评论管理',
      },
    },
    {
      path: '/nb/goods/comment/detail',
      component: () => import('@/views/nb/goods/comment/detail.vue'),
      meta: {
        title: '评论详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    // 展馆管理
    {
      path: '/nb/goods/area',
      component: () => import('@/views/nb/goods/area/index.vue'),
      meta: {
        title: '区域馆管理',
        icon: 'iconcaiwurenminbizhifux',
        keepAlive: true,
      },
    },
    {
      path: '/nb/goods/area/detail',
      component: () => import('@/views/nb/goods/area/detail.vue'),
      meta: {
        title: '展馆创建',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    // 特色馆
    {
      path: '/nb/goods/areaSpecial',
      component: () => import('@/views/nb/goods/areaSpecial/index.vue'),
      meta: {
        title: '特色馆管理',
        keepAlive: true,
      },
    },
    {
      path: '/nb/goods/areaSpecial/goods',
      component: () => import('@/views/nb/goods/areaSpecial/goods.vue'),
      meta: {
        title: '特色馆商品管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/areaSpecial/shop',
      component: () => import('@/views/nb/goods/areaSpecial/shop.vue'),
      meta: {
        title: '特色馆店铺管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/goods/priceChange',
      name: 'PriceChange',
      component: () => import('@/views/ldx/priceChange/priceChange.vue'),
      meta: {
        title: '改价记录',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
  ],
};

export default goodsRouter;
